<script setup lang="ts">
const { t } = useI18n();
const user = useUser();
</script>

<template>
  <div class="outside-layout min-h-screen bg-neutral-light-50 dark:bg-neutral-dark-900">
    <!-- Blue background -->
    <div class="absolute left-0 top-0 z-10 h-[320px] w-full bg-dark-blue-500" />

    <AuthHeader class="pxl-container relative z-30 py-6">
      <div
        v-if="!user"
        id="header-actions"
      >
        <UButton
          size="sm"
          name="sign-up"
          class="border border-white bg-transparent text-white"
          :to="{ path: '/auth/sign-up/', query: { redirectUrl: $route.fullPath } }"
          :label="t('labels.sign_up')"
        />
      </div>
    </AuthHeader>

    <div class="pxl-container relative z-20 py-12">
      <div class="pxl-block mx-auto w-full max-w-[880px] md:py-[4.5rem]">
        <NuxtPage />
      </div>
    </div>
  </div>
</template>
